import * as React from "react"
import { Box, Image, useBreakpointValue } from "@chakra-ui/react"

import { TickerBar } from "./TickerBar"

export function Hero() {
  const handleScrollDown = () => {
    window.scrollBy({ top: 700, left: 0, behavior: "smooth" })
  }

  // Use different video URLs based on screen size
  const videoUrl = useBreakpointValue({
    base: "https://player.vimeo.com/video/1069201826?badge=0&player_id=0&app_id=58479&background=1", // mobile
    md: "https://player.vimeo.com/video/1067775463?badge=0&player_id=0&app_id=58479&background=1"    // desktop
  })

  return (
    <Box position="relative">
      <TickerBar />

      <Image
        alt="scroll naar beneden knop"
        src="/hero-arrow.png"
        width="200px"
        height="200px"
        position="absolute"
        bottom={{ base: -24, md: -24 }}
        ml="auto"
        mr="auto"
        right={0}
        left={0}
        onClick={handleScrollDown}
        cursor="pointer"
        zIndex={100}
      />

      <Box 
        h={{ base: "85vh", md: "103vh" }} 
      >
        <Box
          position={{ base: "absolute", md: "relative" }}
          top={0}
          bottom={0}
          left={0}
          right={0}
          width="100%"
          height="100%"
          overflow="hidden"
          bgColor="background"
        >
          <Box
            as="iframe"
            src={`${videoUrl}&background_color=000000&transparent=0`}
            allow="autoplay"
            title="Welkom bij Kunstbende"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            width={{ 
              base: "calc(177.78 * (100vh - 40px))",
              md: "100%" 
            }}
            height="100%"
            sx={{
              aspectRatio: {
                base: "9/16",
                md: "16/9"
              },
              backgroundColor: "background",
              body: {
                backgroundColor: "background"
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
