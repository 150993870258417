import * as React from "react"
import { Box, Stack, useBreakpointValue } from "@chakra-ui/react"
import Head from "next/head"

import { FeaturedProjects } from "components/FeaturedProjects"
import { Hero } from "components/Hero"
import { Intro } from "components/Intro"
import { JoinTheKlub } from "components/JoinTheKlub"
import { Klasses } from "components/Klasses"
import { Kollaborate } from "components/Kollaborate"
import { Kompetitions } from "components/Kompetitions"
import { Kourses } from "components/Kourses"
import { Partners } from "components/Partners"

import { Benefits } from "./Benefits"

export default function Home() {

  return (
    <>
      <Head>
        <title>Kunstbende</title>
      </Head>

      <Box mb={28}><Hero /></Box>

      <Box px={{ base: 6, md: 20, lg: 28 }} mb={{ base: 28, lg: 24 }}>
        <Intro />
      </Box>

      <Box px={{ base: 6, md: 20, lg: 28 }} mb={{ base: 28, lg: 24 }}>
        <Benefits />
      </Box>

      <Stack w="100%" px={{ base: 6, md: 20, lg: 28 }} mb={{ base: 40, lg: 24 }}>
        <FeaturedProjects />
      </Stack>

      <Stack w="100%" pl={{ base: 0, md: 20, lg: 28 }} pr={0} mb={{ base: 20, lg: 20 }}>
        <Kollaborate />
      </Stack>

      <Stack w="100%" mb={{ base: 28, lg: 28 }} spacing={{ base: 4, md: 4 }}>
        <Klasses />
      </Stack>

      <Stack w="100%" pl={{ base: 6, md: 20, lg: 28 }} pr={0} mb={{ base: 40, lg: 20 }}>
        <Kourses />
      </Stack>

      <Stack w="100%" px={{ base: 6, md: 20, lg: 28 }} mb={{ base: 40, lg: 24 }}>
        <Kompetitions />
      </Stack>

      <Stack w="100%" px={{ base: 6, md: 20, lg: 28 }} mb={{ base: 40, lg: 40 }}>
        <JoinTheKlub />
      </Stack>

      <Stack w="100%" px={{ base: 6, md: 20, lg: 28 }} mb={{ base: 20, lg: 32 }}>
        <Partners />
      </Stack>
    </>
  )
}
